import React from "react"
import Newsletter from "./newsletter"

const Modal = ({ isOpen, onClose }) => {
  return (
    <div
      className={`${isOpen ? "flex" : "hidden"} fixed z-50 inset-0 w-full h-full overflow-auto items-center justify-center`}
      data-sal="fade"
      onClick={onClose}
      onKeyDown={onClose}
      aria-hidden="true"
    >
      <div
        className="w-5/6 bg-lightBlue p-5 lg:py-10 lg:px-28 relative"
        onClick={e => e.stopPropagation()}
        aria-hidden="true"
      >
        <span
          className="z-20 text-4xl cursor-pointer absolute top-2 right-6 hover:cursor-pointer hover:text-purple"
          onClick={onClose}
          onKeyDown={onClose}
          role="button"
          aria-hidden="true"
        >
          &times;
        </span>
        <div className="overflow-hidden">
          <Newsletter />
        </div>
      </div>
    </div>
  )
}

export default Modal
