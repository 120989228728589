import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import TeamMember from "../components/teamMember"
import Modal from "../components/modal"

const IndexPage = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <b className="font-bold">{text}</b>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        return (
          <a href={uri} className="underline">
            {children}
          </a>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2>{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3>{children}</h3>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="whitespace-pre-line">
          {children}
        </p>
      ),
    },
  }

  const companiesOptions = {
    ...options,
    renderNode: {
      ...options.renderNode,
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 data-sal="slide-left" data-sal-duration="1000" data-sal-delay="300" className="text-2xl lg:text-3xl font-bold text-black pb-5">{children}</h3>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p data-sal="slide-left" data-sal-duration="1000" data-sal-delay="300" className="whitespace-pre-line font-serif text-black text-xl">
          {children}
        </p>
      ),
    },
  }

  useEffect(() => {
    if (localStorage.getItem("pop-up") == null) {
      setTimeout(() => {
        localStorage.setItem("pop-up", true)
        setIsOpen(true)
      }, 5000)
    }
  }, [])

  const teamContentful = data.allContentfulTeamMember.nodes
  const homeContentful = data.allContentfulHomePage.nodes[0]
  const advisors = data.allContentfulAdvisor.nodes
  const companies = data.allContentfulCompanies.nodes

  return (
    <>
      <Seo title="Home" />
      <Header siteTitle={`Dry Line Partners`} />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <div className="bg-hero-pattern bg-no-repeat bg-center">
        <div data-sal="fade" className="flex flex-col lg:p-10 lg:pb-20 container mx-auto text-center">
          <h1 className="text-black text-left px-10 py-10 lg:p-10 block font-semibold leading-normal text-4xl lg:text-7xl">
            {homeContentful.heroTitleSection1}{" "}
            {homeContentful.heroTitleSection2.split(" ").map(text =>
              <span className="text-center bg-clip-text text-transparent animate-shine bg-gradient-to-r from-peach via-blue to-peach bg-200%">
                {text}{" "}
              </span>  
            )}
            {homeContentful.heroTitleSection3}
          </h1>
          <div data-sal-duration="1000" data-sal="slide-down" data-sal-delay="300" className="absolute top-72 right-24 hidden invisible lg:visible lg:block">
            <button onClick={() => scrollTo("#team-container")}>
              <StaticImage
                src="../images/scroll-down.png"
                width={100}
                alt="scroll-down"
              />
            </button>
          </div>
          <div className="flex flex-col lg:flex-row space-around items-end">
            <Link data-sal="slide-up" data-sal-delay="300" to={"/"+homeContentful.heroCtaLink} className="text-purple text-left lg:mr-10 px-10 font-light text-lg">
              <span className="text-2xl lg:text-4xl pr-5 text-black">↘</span>
              <span className="hover:underline">
                {/* WANT TO PRESENT YOUR OPPORTUNITY? */}
                {homeContentful.heroCtaText}
              </span>
            </Link>
            <div className="text-black font-serif text-left px-10 py-10 lg:py-0 lg:w-1/2 block text-xl">
              {/* We deploy operational playbooks learned from past company-building experiences to make businesses better and more durable. */}
              {renderRichText(homeContentful.heroDescription, options)}
            </div>
          </div>
        </div>
      </div>
      <div id="team-container" className="bg-black p-5 lg:p-20">
        <div className="container p-5 lg:p-20">
          <h2 data-sal-duration="1000" data-sal="slide-right" className="text-3xl lg:text-5xl font-bold text-white">
            {homeContentful.teamTitle}
          </h2>
          <h3 data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500" className="font-serif py-10 mb-10 text-white text-xl max-w-lg">
            {renderRichText(homeContentful.teamDescription)}
          </h3>
          <div className="">
            <div className="grid grid-cols-2 lg:grid-cols-3 lg:gap-24 gap-6">
              {teamContentful?.map((member, index) => (
                <TeamMember member={member} index={index} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-purple">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row items-start py-20 px-10 lg:py-40 lg:px-20">
            <h2 data-sal="slide-right" data-sal-duration="1000" className="text-3xl lg:text-5xl lg:w-1/2 font-bold text-black py-5 lg:py-0">
              {homeContentful.advisorsTitle}
            </h2>
            <p className="font-serif text-black text-xl lg:w-1/2">
              <span data-sal="slide-left" data-sal-duration="1000" className="block max-w-sm">
                {renderRichText(homeContentful.advisorsDescription)}
              </span>
              <Link data-sal="slide-left" data-sal-delay="500" data-sal-duration="1000" className="text-white block mt-10 hover:underline" to={"/"+homeContentful.advisorsCtaLink}>
                {homeContentful.advisorsCtaText}
              </Link>
            </p>
          </div>
        </div>
        <div className="w-full grid grid-cols-2 lg:grid-cols-4">
          {advisors?.map((advisor, index) => {
            const image = getImage(advisor.logo);
            return (
              <div key={advisor.id} className="font-serif border border-white p-5 md:p-10 lg:p-20 h-80 flex items-center w-full">
                {/* <img data-sal="fade" data-sal-delay={index * 100} data-sal-duration="1000" src={advisor.logo} alt={advisor.name} /> */}
                <GatsbyImage data-sal="fade" data-sal-delay={index * 100} data-sal-duration="1000" image={image} alt={advisor.name} />
              </div>
            )
          })}
        </div>
      </div>
      <div className="bg-white relative">
        <div className="container text-center mx-auto">
          <div className="flex flex-col lg:flex-row items-start lg:pt-60 lg:lb-24 px-10 py-20 lg:px-20">
            <div className="text-left lg:w-1/2 pr-10">
              <h2 data-sal="slide-left" data-sal-duration="1000" data-sal-delay="200" className="text-3xl lg:text-5xl font-bold text-black pb-20">
                {homeContentful.companiesTitle}
              </h2>
              {renderRichText(homeContentful.companiesDescription, companiesOptions)}
            </div>
            <div data-sal-duration="1000" data-sal="fade" data-sal-delay="800" className="lg:w-1/2">
              <StaticImage
                className="lg:-top-28"
                src="../images/our-companies.jpg"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Our Companies"
              />
            </div>
          </div>
        </div>
        <div className="w-full grid grid-cols-2 lg:grid-cols-4 border-[0.5px] border-peach">
          {companies?.map((company, index) => {
            const image = getImage(company.logo)
            return (
              <a className="font-serif border-[0.5px] border-peach p-5 lg:p-20 h-40 lg:h-80 flex items-center w-full" key={index} href={company.link} rel="noreferrer" target="_blank">
                <GatsbyImage data-sal="fade" data-sal-delay={index * 100} data-sal-duration="1000" image={image} alt={company.name} />
              </a>
            )
          })}
        </div>
      </div>
      <Footer />
    </>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulHomePage {
      nodes {
        id
        heroTitleSection1
        heroTitleSection2
        heroTitleSection3
        heroDescription {
          raw
        }
        heroCtaText
        heroCtaLink
        teamTitle
        teamDescription {
          raw
        }
        advisorsTitle
        advisorsDescription {
          raw
        }
        advisorsCtaText
        advisorsCtaLink
        companiesTitle
        companiesDescription {
          raw
        }
      }
    }
    allContentfulTeamMember(sort: { fields: order }) {
      nodes {
        id
        firstname
        email
        lastname
        linkedin
        twitter
        email
        role
        slug
        avatar {
          gatsbyImageData(width: 400)
        }
      }
    }
    allContentfulAdvisor(sort: {fields: order}) {
      nodes {
        id
        name
        logo {
          gatsbyImageData
        }
      }
    }
    allContentfulCompanies(sort: {fields: order}) {
      nodes {
        id
        name
        link
        logo {
          gatsbyImageData
        }
      }
    }
  }
`
